/**
 * polyfills.js
 *
 * This file provides polyfills for JavaScript features that are not supported in older browsers.
 * Polyfills ensure compatibility by adding missing functionality so that the application
 * can run in environments that do not have built-in support for newer JavaScript features.
 *
 * Current polyfills:
 * - Object.fromEntries: A modern ES2019+ method that converts a list of key-value pairs into an object.
 *                       This polyfill is added to support older browsers that do not have this method.
 */

// Check if Object.fromEntries is not natively supported in the current browser.
if (!Object.fromEntries) {
  /**
   * Polyfill for Object.fromEntries.
   * Converts an iterable (e.g., an array of key-value pairs) into an object.
   * Example:
   *   Object.fromEntries([['key1', 'value1'], ['key2', 'value2']])
   *   => { key1: 'value1', key2: 'value2' }
   *
   * @param {Iterable} iterable - An array or any iterable object of key-value pairs.
   * @returns {Object} - A new object constructed from the key-value pairs.
   */
  Object.fromEntries = function (iterable) {
    return [...iterable].reduce((obj, [key, val]) => {
      obj[key] = val; // Add the key-value pair to the result object
      return obj; // Return the updated object after each iteration
    }, {}); // Start with an empty object as the initial value
  };
}
