//
// Initialize choices.js
//

window.selectors = {};

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".choices").forEach(initChoices);
});

function initChoices(el) {
  if (!el.id) {
    console.error("Element is missing an ID:", el);
    return; // Skip initialization if the element doesn't have an ID
  }

  const classes = [...el.classList];
  const config = {
    allowHTML: true,
    itemSelectText: "",
    placeholder: true,
    placeholderValue: el.dataset.placeholder || "",
    searchEnabled: classes.includes("searchable"),
    searchPlaceholderValue: "Search options ...",
    removeItemButton: classes.includes("clearable"),
    duplicateItemsAllowed: !classes.includes("defaultSelected"),
    shouldSort: false,
    renderSelectedChoices: classes.includes("renderSelected") ? "always" : "auto",
  };

  const templates = {
    "people-narrow": createTemplateFn(getPeopleNarrowHtml),
    people: createTemplateFn(getPeopleHtml),
    role: createTemplateFn(getRoleHtml),
    importance: createTemplateFn(getImportanceHtml),
    location: createTemplateFn(getLocationHtml),
    "location-narrow": createTemplateFn(getLocationNarrowHtml),
    happiness: createTemplateFn(getHappinessHtml),
    area: createTemplateFn(getAreaHtml),
    languages: createTemplateFn(getLanguagesHtml),
    "indigenous-status": createTemplateFn(getIndigenousStatusHtml),
    "sexual-orientation": createTemplateFn(getSexualOrientationHtml),
  };

  const matchingTemplate = classes.find((cls) => templates[cls]);
  config.callbackOnCreateTemplates = function (templateOptions) {
    const extendedTemplates = {};

    if (matchingTemplate) {
      // Override the item template
      extendedTemplates.item = extendDefaultTemplate("item", function (defaultElement, templateOptions, choice, removeItemButton) {
        const customHtml = templates[matchingTemplate].item(choice);

        // Explicitly handle the 'disabled' property
        if (typeof choice.disabled === "undefined") {
          choice.disabled = false; // Default to false if not defined
        }

        if (defaultElement.firstChild) {
          defaultElement.replaceChild(customHtml, defaultElement.firstChild);
        } else {
          defaultElement.appendChild(customHtml);
        }

        if (choice.disabled) {
          defaultElement.setAttribute("aria-disabled", "true");
        } else {
          defaultElement.removeAttribute("aria-disabled");
        }

        return defaultElement;
      });

      // Override the choice template
      extendedTemplates.choice = extendDefaultTemplate("choice", function (defaultElement, templateOptions, choice, selectText) {
        const customHtml = templates[matchingTemplate].choice(choice);

        // Explicitly handle the 'disabled' property
        if (typeof choice.disabled === "undefined") {
          choice.disabled = false;
        }

        if (defaultElement.firstChild) {
          defaultElement.replaceChild(customHtml, defaultElement.firstChild);
        } else {
          defaultElement.appendChild(customHtml);
        }

        // Handle 'clearable' class and placeholder
        if (classes.includes("clearable") && defaultElement.classList.contains("choices__placeholder")) {
          defaultElement.classList.add("d-none");
        } else {
          defaultElement.classList.add("pe-2");
        }

        return defaultElement;
      });
    }

    return extendedTemplates;
  };

  // Initialize and save dropdown into a global variable for possible future needs
  try {
    window.selectors[el.id] = new Choices(el, config);
  } catch (error) {
    console.error(`Failed to initialize Choices for element ${el.id}:`, error);
  }

  // Add an opened and has-selection class to the choices wrapper when an option is pre-selected
  function updateWrapperClasses(el) {
    if (el.selectedOptions && el.selectedOptions.length > 0 && el.selectedOptions[0].innerText !== "") {
      el.parentNode.parentNode.classList.add("opened", "has-selection");
    }
  }

  updateWrapperClasses(el); // Initial check
  el.addEventListener("addItem", () => updateWrapperClasses(el)); // Event listener for when an item is added

  // Scroll the selected option into view when opened
  el.addEventListener("showDropdown", () => {
    // When selected, remove the red border
    el.parentNode.parentNode.classList.remove("required-field-error");

    // Add an opened class to the choices wrapper when opened (so we can use the floating-forms CSS)
    el.parentNode.parentNode.classList.add("opened");

    // Scroll the selected option into view
    const listContainer = el.parentNode.parentNode.querySelector(".choices__list--dropdown .choices__list");

    if (listContainer.querySelectorAll(".choices__item--choice").length > 7) {
      const selectedOption = listContainer.querySelector(".is-selected");

      if (selectedOption) {
        const containerTop = listContainer.getBoundingClientRect().top;
        const containerBottom = listContainer.getBoundingClientRect().bottom;

        const optionTop = selectedOption.getBoundingClientRect().top;
        const optionBottom = selectedOption.getBoundingClientRect().bottom;

        if (optionTop < containerTop) {
          // If the option is above the current viewable area, scroll upward
          listContainer.scrollTop -= containerTop - optionTop;
        } else if (optionBottom > containerBottom) {
          // If the option is below the current viewable area, scroll downward
          listContainer.scrollTop += optionBottom - containerBottom;
        }
      }
    }
  });

  el.addEventListener("hideDropdown", () => {
    // Remove the opened class from the choices wrapper when closed if empty
    if (el.options && el.options.length === 0) {
      el.parentNode.parentNode.classList.remove("opened");
    }
  });

  el.addEventListener("change", () => {
    // Remove the opened class from the choices wrapper when closed if empty
    if (el.options && el.options.length === 0) {
      el.parentNode.parentNode.classList.remove("opened");
    }

    // Add a class to the wrapper when at least one option is selected
    el.parentNode.parentNode.classList.toggle("has-selection", el.options.length > 0);
  });

  // Clear the selection if empty class is included
  if (classes.includes("empty")) {
    setTimeout(() => {
      window.selectors[el.id].removeActiveItems();
    }, 0);
  }
}

window.initChoices = initChoices;

//
// Create the HTML for the item and choice
//
function getPeopleNarrowHtml(choice) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getPeopleNarrowHtml:", choice);
    return "<div>Invalid item</div>";
  }

  // For non-blank choices, ensure customProperties exist
  if (choice.value !== "" && (!choice.customProperties || choice.customProperties === null)) {
    console.warn("Missing customProperties in getPeopleNarrowHtml for choice:", choice);
    return "<div>Invalid item</div>";
  }

  if (["", "Anonymous"].includes(choice.label)) return anonymousHtml();

  if (choice.value === "") {
    // Handle blank options
    return `<div class="d-none align-items-center">
      <span>${choice.label}</span>
    </div>`;
  }

  return `
    <div class="d-inline-flex align-items-center" data-location_ids="${choice.customProperties.location_ids}" data-role="${choice.customProperties.role || null}">
      <span class="bg-role-${choice.customProperties.role || null} me-2 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10"></span>
      <span>${choice.label}</span>
    </div>`;
}

function getPeopleHtml(choice, isItem) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getPeopleHtml:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value !== "" && (!choice.customProperties || choice.customProperties === null)) {
    console.warn("Missing customProperties in getPeopleHtml for choice:", choice);
    return "<div>Invalid item</div>";
  }

  if (["", "Anonymous"].includes(choice.label)) return anonymousHtml();

  if (choice.value === "") {
    // Handle blank options
    return `<div class="d-none align-items-center ${isItem ? "pe-4" : ""}">
      <span class="text-nowrap">${choice.label}</span>
    </div>`;
  }

  return `
    <div class="d-flex align-items-center ${isItem ? "pe-4" : ""}" data-location_ids="${choice.customProperties.location_ids}" data-role="${choice.customProperties.role || null}">
      <span class="text-nowrap">${choice.label}</span>
      ${choice.customProperties.role == "user" && choice.customProperties.dob ? `<span class="text-muted opacity-50 small ms-2 text-nowrap">${choice.customProperties.dob || ""}</span>` : ""}
      ${choice.customProperties.role !== undefined ? `<span class="badge rounded-pill bg-lighter text-nowrap text-role-${choice.customProperties.role} lh-sm ${isItem ? "ms-2 fs-8" : "ms-auto me-n1"}">${choice.customProperties.role_label}</span>` : ""}
    </div>`;
}

function getRoleHtml(choice) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getRoleHtml:", choice);
    return "<div>Invalid item</div>";
  }

  // No need to check customProperties here, as it's not used.

  const isDisabled = choice.disabled !== undefined ? choice.disabled : false;

  return `
    <div class="${choice.value === "" ? "d-none" : "d-inline-flex align-items-center"} ${isDisabled ? "disabled" : ""}" aria-disabled="${isDisabled}">
      ${choice.value !== "" ? `<span class="${choice.value === "anonymous" ? "bg-mid" : `bg-role-${choice.value}`} me-2 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10"></span>` : ""}
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
}

function getImportanceHtml(choice) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getImportanceHtml:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value !== "" && (choice.customProperties === undefined || choice.customProperties === null)) {
    console.warn("Missing customProperties in getImportanceHtml for choice:", choice);
    return "<div>Invalid item</div>";
  }

  return `
    <div class="${choice.value === "" ? "d-none" : "d-inline-flex"} align-items-center">
      ${choice.value !== "" ? `<span class="me-2 ms-n1 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10 bg-importance-${choice.value}"></span>` : ""}
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
}

function getLocationHtml(choice, isItem) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getLocationHtml:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value !== "" && (!choice.customProperties || choice.customProperties === null)) {
    console.warn("Missing customProperties in getLocationHtml for choice:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value === "") {
    // Handle blank options
    return `<div class="d-none align-items-center">
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
  }

  return `
    <div class="d-flex align-items-center" data-type="${choice.customProperties.type}">
      <span class="me-2 ms-n1 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10 bg-location-${choice.value}"></span>
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
      ${isItem ? "" : `<span class="ms-auto badge rounded-pill bg-light text-mid lh-sm">${choice.customProperties.type_label}</span>`}
    </div>`;
}

function getLocationNarrowHtml(choice, isItem) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getLocationNarrowHtml:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value !== "" && (!choice.customProperties || choice.customProperties === null)) {
    console.warn("Missing customProperties in getLocationNarrowHtml for choice:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value === "") {
    // Handle blank options
    return `<div class="d-none align-items-center">
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
  }

  return `
    <div class="d-flex align-items-center" data-type="${choice.customProperties.type}">
      <span class="me-2 ms-n1 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10 bg-location-${choice.value}"></span>
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
}

function getHappinessHtml(choice) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getHappinessHtml:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value !== "" && (!choice.customProperties || choice.customProperties === null)) {
    console.warn("Missing customProperties in getHappinessHtml for choice:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value === "") {
    // Handle blank options
    return `<div class="d-none position-relative text-nowrap happiness-icon">
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
  }

  return `
    <div class="position-relative text-nowrap happiness-icon">
      <span class="rounded-circle s20x20 d-flex align-items-center justify-content-center ${choice.customProperties.class} position-absolute top-0 start-0">
        <svg class="bi" width="20" height="20">
          <use xlink:href="#${choice.customProperties.icon}"></use>
        </svg>
        <span class="visually-hidden">${choice.label}</span>
      </span>
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
}

function getAreaHtml(choice) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getAreaHtml:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value !== "" && (!choice.customProperties || choice.customProperties === null)) {
    console.warn("Missing customProperties in getAreaHtml for choice:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value === "") {
    // Handle blank options
    return `<div class="d-none position-relative text-nowrap area-icon">
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
  }

  return `
    <div class="position-relative text-nowrap area-icon">
      <span class="rounded-circle s20x20 d-flex align-items-center justify-content-center bg-brand text-white position-absolute top-0 start-0">
        ${choice.customProperties.icon.replace("viewBox", "width=20 height=20 viewBox")}
      </span>
      <span class="text-nowrap text-truncate lh-sm">${choice.label}</span>
    </div>`;
}

// Function for survey languages template
function getLanguagesHtml(choice, isItem) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getLanguagesHtml:", choice);
    return "<div>Invalid item</div>";
  }

  // Assuming customProperties are not essential here

  if (choice.value === "") {
    // Handle blank options
    return `<div class="d-none align-items-center">
      <span class="text-nowrap">${choice.label}</span>
    </div>`;
  }

  return `
    <div class="d-inline-flex align-items-center">
      <img src="/images/languages/${choice.value}.png" alt="${choice.value}" class="me-2 s24x24 rounded-circle" />
      <span class="text-nowrap">${choice.label}</span>
    </div>`;
}

// Function for indigenous status template
function getIndigenousStatusHtml(choice) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getIndigenousStatusHtml:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value === "") {
    return `<div class="d-inline-flex align-items-center">${choice.label}</div>`;
  }

  return `
    <div class="d-inline-flex align-items-center">
      <img src="/images/indigenous-status/${choice.value.toLowerCase().replace(/ /g, "_").replace("/", "_")}.png" alt="${choice.value}" class="me-2 s24x24 rounded-circle" />
      ${choice.label}
    </div>`;
}

// Function for sexual orientation template
function getSexualOrientationHtml(choice) {
  if (!choice || choice.label === undefined || choice.label === null) {
    console.warn("Invalid choice passed to getSexualOrientationHtml:", choice);
    return "<div>Invalid item</div>";
  }

  if (choice.value === "") {
    return `<div class="d-inline-flex align-items-center">${choice.label}</div>`;
  }

  return `
    <div class="d-inline-flex align-items-center">
      <img src="/images/sexual-orientation/${choice.value.toLowerCase().replace(/ /g, "_")}.png" alt="${choice.value}" class="me-2 s24x24 rounded-circle" />
      ${choice.label}
    </div>`;
}

// Helper function
function anonymousHtml() {
  return `
    <div class="d-inline-flex align-items-center text-secondary">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill opacity-75 me-2" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
      </svg>
      <span class="opacity-75">Anonymous</span>
    </div>`;
}

//
// Render functions
//

// Create the template functions for item and choice
function createTemplateFn(getHtmlFn) {
  return {
    item: (choice) => createDOMElementFromHTML(getHtmlFn(choice, true)),
    choice: (choice) => createDOMElementFromHTML(getHtmlFn(choice, false)),
  };
}

function createDOMElementFromHTML(htmlStr) {
  if (!htmlStr || typeof htmlStr !== "string") {
    console.warn("Invalid HTML string:", htmlStr);
    return document.createElement("div"); // Return an empty div in case of error
  }

  const template = document.createElement("template");
  template.innerHTML = htmlStr.trim();
  return template.content.firstChild;
}

function extendDefaultTemplate(templateName, fn) {
  const defaultTemplateFn = Choices.defaults.templates[templateName];
  if (!defaultTemplateFn) {
    console.warn(`No default template found for ${templateName}.`);
    return (...args) => document.createElement("div");
  }

  return function (...args) {
    const defaultElement = defaultTemplateFn.apply(this, args);
    if (!defaultElement) {
      console.warn(`Default template for ${templateName} returned undefined.`);
      return document.createElement("div");
    }
    return fn.apply(this, [defaultElement, ...args]);
  };
}
